import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-user',
    label: 'menu.login',
    to: `${adminRoot}/vien`,
    roles: [UserRole.Admin, UserRole.Editor],
    // subs: [
    //   {
    //     icon: 'simple-icon-paper-plane',
    //     label: 'menu.start',
    //     to: `${adminRoot}/vien/start`,
    //     // roles: [UserRole.Admin],
    //   },
    // ],
  },
  {
    icon: 'iconsminds-stop',
    label: 'menu.home',
    to: `${adminRoot}`
     // roles: [UserRole.Editor],
    // subs: [
    //   {
    //     icon: 'simple-icon-paper-plane',
    //     label: 'menu.second',
    //     to: `${adminRoot}/second-menu/second`,
    //   },
    // ],
  },
  {
    icon: 'iconsminds-home',
    label: 'My Addresses',
    to: `${adminRoot}/blank-page`,
  },
  {
    icon: 'iconsminds-box-with-folders',
    label: 'My Orders',
    to: `${adminRoot}/blank-page`,
  },
  {
    icon: 'iconsminds-library',
    label: 'menu.docs',
    to: 'https://vien-docs.coloredstrategies.com/',
    newWindow: true,
  },
];
export default data;
