<!-- <button (click)="openModal()" id="button-basic" type="button" class="header-icon btn btn-empty">
    <i class="simple-icon-login"></i>
</button> -->
<ng-template #template>
    <div>
        <form novalidate>
            <div class="modal-header">
                <h5>Login</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label class="form-group has-float-label">
                    <input class="form-control" type="text" placeholder="" required #username="ngModel" name="username"
                        [(ngModel)]="usernameModel" />
                    <span>{{ "user.username" | translate }}</span>
                    <div *ngIf="!username.valid && isSubmitted" class="invalid-tooltip">
                        Fullname is required!
                    </div>
                </label>

                <label class="form-group has-float-label">
                    <input class="form-control" type="tel" required #phone="ngModel" name="phone"
                        [(ngModel)]="phoneModel" />
                    <span>{{ "user.phone" | translate }}</span>
                    <div *ngIf="!phone.valid && isSubmitted" class="invalid-tooltip">
                        Phone is required!
                    </div>
                </label>

                <label class="form-group has-float-label" *ngIf="isOtpSent">
                    <input class="form-control" type="text" placeholder="" required #otp="ngModel" name="otp"
                        [(ngModel)]="otpModel" />
                    <span>{{ "user.otp" | translate }}</span>
                    <div *ngIf="!otp.valid && isOtpSubmitted" class="invalid-tooltip">
                        OTP is required!
                    </div>
                    <div *ngIf="otp.valid && isOtpSubmitted && isWrongOtp" class="invalid-tooltip">
                        Invalid OTP!
                    </div>
                    <div *ngIf="otp.valid && isOtpSubmitted && isExpiredOtp" class="invalid-tooltip">
                        OTP is expired!
                    </div>
                </label>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-between align-items-center">
                    <div id="login-btn"></div>
                    <app-state-button *ngIf="!isOtpSent" [btnClass]="'btn btn-primary btn-lg btn-shadow'"
                        [currentState]="buttonState" [isDisabled]="buttonDisabled" (click)="getOtp()">
                        {{ "user.otp-button" | translate | uppercase }}
                    </app-state-button>
                    <app-state-button *ngIf="isOtpSent" [btnClass]="'btn btn-primary btn-lg btn-shadow'"
                        [currentState]="buttonState" [isDisabled]="buttonDisabled" (click)="confirmOtp()">
                        {{ "user.login-button" | translate | uppercase }}
                    </app-state-button>
                </div>
            </div>
        </form>
    </div>
</ng-template>