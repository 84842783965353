import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/data/category.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
})
export class CategoriesListComponent implements OnInit {
  categories = [];

  constructor(private categoryService: CategoryService, private router: Router) {}

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.categoryService
      .getProductCategories(localStorage.getItem('bizcat'))
      .subscribe((categoryData) => {
        this.categories = categoryData.filter(
          (x) => x.id !== localStorage.getItem('prodcat')
        );
      });
  }

  showProductsFromCategory(categoryId: string) {
    localStorage.setItem('prodcat', categoryId);
    this.getCategories();
    this.router.navigate(['/' + localStorage.getItem('storeid') + '/' + categoryId]);
  }
}
