import { Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CategoryService } from 'src/app/data/category.service';
import { Product, ProductService } from 'src/app/data/product.service';

@Component({
  selector: 'app-modal-process-create-product',
  templateUrl: './modal-process-create-product.component.html',
})
export class ModalProcessCreateProductComponent {
  modalRef: BsModalRef;
  isModalOpen = false;
  config = {
    animated: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-lg',
  };
  productCategories = [];

  productModel = new Product();

  constructor(
    private modalService: BsModalService,
    private productService: ProductService,
    private categoryService: CategoryService
  ) {}

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, this.config);
  }

  hideModal(): void {
    this.isModalOpen = false;
    this.modalRef.hide();
  }

  async Continue() {
    if (this.productModel) {
      const p = this.productService.getProductParams(this.productModel);
      if (p.name && p.description) {
        this.createProduct(p);
      }
    }
  }

  chooseBusinessCategory() {
    this.categoryService
      .getProductCategories(this.productModel.bizcat)
      .subscribe((categoryData) => {
        this.productCategories = categoryData;
      });
  }

  createProduct(params) {
    this.productService
      .createProduct(params)
      .then(async () => {
        alert('Added New Product Successfully');
        this.productModel = {
          id: '',
          name: '',
          regularprice: 0,
          saleprice: 0,
          brand: '',
          weight: '',
          isinstock: false,
          ispopular: false,
          description: '',
          image: '',
          bizcat: '',
          prodcat: '',
        };
      })
      .catch((err) => console.log(err));
  }
}