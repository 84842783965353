<button id="button-basic" type="button" id="menuButton" class="header-icon btn btn-empty"
    (click)="toggleSwitcher($event)">
    <i class="simple-icon-menu"></i>
</button>
<div class="{{toggleClass}} side-menu">
    <!-- <a (click)="toggleSwitcher($event)" class="c-pointer theme-button">
        <i class="iconsminds-shopping-cart"></i>
    </a> -->
    <div class="cart-body">
        <div class="p-4 ">
            <p class="text-muted mb-2" (click)="handleMenuClick('login')">Login</p>
        </div>
        <app-footer>Proceed</app-footer>
    </div>
</div>