import { Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  CategoryService,
  ProductCategory,
} from 'src/app/data/category.service';

@Component({
  selector: 'app-modal-process-create-category',
  templateUrl: './modal-process-create-category.component.html',
})
export class ModalProcessCreateCategoryComponent {
  modalRef: BsModalRef;
  isModalOpen = false;
  config = {
    animated: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-lg',
  };

  categoryModel = new ProductCategory();

  constructor(
    private modalService: BsModalService,
    private categoryService: CategoryService
  ) {}

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, this.config);
  }

  hideModal(): void {
    this.isModalOpen = false;
    this.modalRef.hide();
  }

  async Continue() {
    if (this.categoryModel) {
      const p = this.categoryService.getCategoryParams(this.categoryModel);
      if (p.name && p.description) {
        this.createCategory(p);
      }
    }
  }

  createCategory(params) {
    this.categoryService
      .createCategory(params)
      .then(async () => {
        alert('Added New Category Successfully');
        this.categoryModel = {
          id: '',
          name: '',
          bizcat: '',
          discount: 0,
          description: '',
          image: '',
          uid: '',
        };
      })
      .catch((err) => console.log(err));
  }
}
