import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { UserService } from 'src/app/data/user.service';
import { UtilsService } from 'src/app/data/utils.service';
import { AddressService } from 'src/app/data/address.service';
import { OrderService } from 'src/app/data/order.service';
import { MapboxService, Feature } from 'src/app/data/mapbox.service';
import { AuthService } from 'src/app/shared/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-wizard-order',
  templateUrl: './wizard-order.component.html',
})
export class WizardOrderComponent {
  usernameModel = 'Demo User';
  phoneModel = '919445192962';

  buttonDisabled = false;
  buttonState = '';
  isSubmitted = false;
  isOtpSubmitted = false;
  isOtpSent = false;
  isWrongOtp = false;
  isExpiredOtp = false;
  confirmation: any;
  otpModel = '';
  selectedDelivery = '';
  selectedPayment = '';
  selectedAddress = '';
  defaultAddress = '';
  selectedFinalAddress = '';
  addressArray = [];
  possibleAddresses = [];
  isSaveAddress = false;
  orderStatus = '';
  specialInstruction: '';

  qpMap: any;

  modalRef: BsModalRef;

  @Input() isLoggedIn: boolean;
  @Output() isLoggedInEmitter: EventEmitter<string> = new EventEmitter();
  @Output() isOrderPlacedEmitter: EventEmitter<string> = new EventEmitter();
  @ViewChild('newAddress') newAddress: TemplateRef<any>;

  constructor(
    private authService: AuthService,
    private notifications: NotificationsService,
    private userService: UserService,
    private utils: UtilsService,
    private addressService: AddressService,
    private orderService: OrderService,
    private mapboxService: MapboxService,
    private modalService: BsModalService
  ) {
    this.utils.getQPMap().subscribe((val) => {
      this.qpMap = val;
    });
    this.getAddress();
  }

  getAddress() {
    const addresses = this.addressService.getUserAddress(
      localStorage.getItem('uid')
    );
    addresses.items.unshift({ fullAddress: 'Add New Address' });
    this.addressArray = addresses.items;
    this.selectedAddress = this.addressArray[addresses.selected];
    this.defaultAddress = addresses.selected;
    //  this.utils.orderUserAddress = this.selectedAddress;
  }

  onAddressChange() {
    if (this.selectedAddress === 'Add New Address') {
      this.modalRef = this.modalService.show(this.newAddress, {
        class: 'modal-lg',
        ignoreBackdropClick: true,
      });
    }
  }

  searchAddress(event) {
    this.mapboxService
      .searchWord(event.target.value)
      .subscribe((features: Feature[]) => {
        const results = features.map((feat) => feat.place_name);
        this.mapPossibleAddresses(features);
      });
  }

  mapPossibleAddresses(results) {
    let tempAddresses = [];
    results.forEach((item) => {
      const address: any = {};
      const zipcode = item.context.filter((val) => val.id.includes('postcode'));
      if (zipcode !== undefined && zipcode[0] !== undefined) {
        if (zipcode[0].text !== undefined) {
          address.zipcode = zipcode[0].text;
        } else {
          address.zipcode = '';
        }
      }

      const state = item.context.filter((val) => val.id.includes('region'));
      if (state !== undefined && state[0] !== undefined) {
        if (state[0].text !== undefined) {
          address.state = state[0].text;
        } else {
          address.state = '';
        }
      }

      const city = item.context.filter((val) => val.id.includes('place'));
      if (city !== undefined && city[0] !== undefined) {
        if (city[0].text !== undefined) {
          address.city = city[0].text;
        } else {
          address.city = '';
        }
      }

      const country = item.context.filter((val) => val.id.includes('country'));
      if (country !== undefined && country[0] !== undefined) {
        if (country[0].text !== undefined) {
          address.country = country[0].text;
        } else {
          address.country = '';
        }
      }

      if (item.address === undefined) {
        if (item.text !== undefined) {
          address.streetaddress = item.text;
        } else {
          address.streetaddress = '';
        }
      } else {
        if (item.text !== undefined) {
          address.streetaddress = item.address + ' ' + item.text;
        } else {
          address.streetaddress = item.address;
        }
      }
      tempAddresses.push(address);
    });
    this.possibleAddresses = tempAddresses;
  }

  addressSelect(address) {
    this.modalRef.hide();
    if (address.streetaddress !== undefined) {
      this.selectedFinalAddress = address.streetaddress;
    }
    if (address.city !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ', ' + address.city;
    }
    if (address.state !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ', ' + address.state;
    }
    if (address.country !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ', ' + address.country;
    }
    if (address.zipcode !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ' - ' + address.zipcode;
    }
    // this.selectedFinalAddress =
    //   address.streetaddress +
    //   ', ' +
    //   address.city +
    //   ', ' +
    //   address.state +
    //   ', ' +
    //   address.country +
    //   ' - ' +
    //   address.zipcode;
    this.addressArray.push({ fullAddress: this.selectedFinalAddress });
    this.selectedAddress = this.selectedFinalAddress;
    this.possibleAddresses = [];
  }

  getOtp(): void {
    this.isSubmitted = true;
    if (
      this.usernameModel === undefined ||
      this.usernameModel === null ||
      this.usernameModel.trim() === '' ||
      this.phoneModel === undefined ||
      this.phoneModel === null ||
      this.phoneModel.trim() === '' ||
      this.buttonDisabled
    ) {
      return;
    }

    this.buttonDisabled = true;
    this.buttonState = 'show-spinner';

    this.authService
      .signInWithPhone(`+${this.phoneModel}`, 'login-btn')
      .then((result) => {
        this.confirmation = result;
        this.isOtpSent = true;
        this.buttonDisabled = false;
        this.buttonState = '';
      })
      .catch((error) => {
        console.log(error);
        this.buttonDisabled = false;
        this.buttonState = '';
        this.notifications.create(
          'Error',
          error.message,
          NotificationType.Bare,
          { theClass: 'outline primary', timeOut: 6000, showProgressBar: false }
        );
      });
  }

  confirmOtp(): void {
    this.isOtpSubmitted = true;
    if (
      this.otpModel === undefined ||
      this.otpModel === null ||
      this.otpModel.trim() === '' ||
      this.buttonDisabled
    ) {
      return;
    }

    this.buttonDisabled = true;
    this.buttonState = 'show-spinner';
    this.confirmation
      .confirm(this.otpModel)
      .then((result) => {
        this.buttonDisabled = false;
        this.buttonState = '';
        this.userService.createUser(result.user.uid, {
          fullname: this.usernameModel,
        });
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('uid', result.user.uid);
        this.isLoggedIn = true;
        this.isLoggedInEmitter.emit('true');
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 'auth/invalid-verification-code') {
          this.isWrongOtp = true;
        } else if (error.code === 'auth/code-expired') {
          this.isExpiredOtp = true;
        }
        this.buttonDisabled = false;
        this.buttonState = '';
      });
  }

  placeOrder() {
    let finalOrder = {};
    const randomNumber = this.utils.getRandomNumber();
    const uid = localStorage.getItem('uid');
    if (this.specialInstruction === undefined) {
      this.specialInstruction = '';
    }
    finalOrder['id'] = randomNumber;
    finalOrder['items'] = this.qpMap['totalCart'];
    finalOrder['totalitemsquantity'] = this.qpMap['totalQuantity'];
    finalOrder['totalcharges'] = this.qpMap['totalPrice'];
    finalOrder['orderplaceddate'] = new Date().toLocaleString();
    finalOrder['customername'] = localStorage.getItem('fullname');
    finalOrder['customerphone'] = localStorage.getItem('phone');
    finalOrder['customerid'] = uid;
    finalOrder['storeid'] = localStorage.getItem('storeid');
    finalOrder['storename'] = localStorage.getItem('storename');
    finalOrder['status'] = 'Pending';
    finalOrder['deliverystatus'] = 'nd'; // means not delivered
    finalOrder['paymentmode'] = this.selectedPayment;
    finalOrder['deliverytype'] = this.selectedDelivery;
    finalOrder['specialinstruction'] = this.specialInstruction;
    if (this.selectedDelivery === 'home' || this.selectedDelivery === 'store') {
      if (this.selectedAddress === 'Add New Address') {
        finalOrder['orderaddress'] = this.selectedFinalAddress;
      } else {
        finalOrder['orderaddress'] = this.selectedAddress;
      }
    }
    if (this.isSaveAddress) {
      this.addressService.addUserAddress(
        uid,
        this.selectedFinalAddress,
        this.defaultAddress
      );
      this.getAddress();
    }
    this.orderService
      .placeOrder(randomNumber, finalOrder)
      .then(async () => {
        finalOrder['id'] = randomNumber;
        this.orderService.saveOrderToStorage(uid, finalOrder, true);
        this.orderStatus = 'success';
        this.utils.setQPMap({});
        this.isOrderPlacedEmitter.emit('placed');
      })
      .catch((err) => {
        this.orderStatus = 'failure';
        console.log(err);
      });
  }
}
