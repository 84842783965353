<div class="row">
    <div class="col-12 list ng-star-inserted">
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4" (click)="goToShops()">
                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row">
                        <div class="rounded-circle m-4 align-self-center list-thumbnail-letters small"> SK </div>
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row">
                        <div class="rounded-circle m-4 align-self-center list-thumbnail-letters small"> SK </div>
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4">

                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4">

                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4">

                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4">

                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4">

                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4">

                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 col-sm-6 mb-4 ng-star-inserted">
                <div class="card d-flex flex-row mb-4">

                    <img alt="Profile" src="/assets/img/profiles/l-1.jpg"
                            class="img-thumbnail border-0 rounded-circle m-4 list-thumbnail align-self-center small">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div class="card-body pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                            <div class="min-width-zero"><a routerlink="./" href="/app/ui/components/cards">
                                    <p class="list-item-heading mb-1 truncate">Sarah Kortney</p>
                                </a>
                                <p class="mb-2 text-muted text-small">Executive Director</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>