import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductService } from 'src/app/data/product.service';
import { UtilsService } from 'src/app/data/utils.service';
@Component({
  selector: 'app-search-product-modal',
  templateUrl: './search-product-modal.component.html',
  styles: [],
})
export class SearchProductModalComponent implements OnInit {
  mainModalRef: BsModalRef;
  confirmModalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-right',
  };
  productSearchKey = '';
  products = [];
  filteredProducts = [];

  @ViewChild('mainTemplate', { static: true }) mainTemplate: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private notifications: NotificationsService,
    private productService: ProductService,
    private utils: UtilsService,
    public router: Router
  ) {}

  ngOnInit() {
  }

  show(): void {
    this.mainModalRef = this.modalService.show(this.mainTemplate, this.config);
  }

  searchProduct() {
    this.filteredProducts = [];
    if (this.productSearchKey.trim() !== '') {
      this.productService.getProducts().subscribe((res) => {
        this.products = res.filter((x) => x.bizcat === localStorage.getItem('bizcat'));
        this.filteredProducts = this.products.filter((product) => {
          return product.name.indexOf(this.productSearchKey.trim()) > -1;
        });
      });
    } else {
      this.notifications.create(
        'Error',
        'Please enter few characters to search',
        NotificationType.Error,
        { theClass: 'outline', timeOut: 3000, showProgressBar: false }
      );
    }
  }
}
