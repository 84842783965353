<ng-template #template>
  <div class="modal-header h-90-px">
    <h5>Edit Addresses</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body overflow-y-auto h-diff-190-vh">
    <div class="row">
      <div class="col-12">
        <div class="mb-4">
          <div>
            <h5 class="mb-4">My addresses</h5>
          </div>
          <div
            class="card mb-4 p-4"
            *ngFor="let address of addresses; let i = index"
            [ngClass]="{ 'card-selected': i === defaultAddress }"
            (click)="makeDefaultAddress(i)"
          >
            <div class="mb-4">
              {{ address.fullAddress }}
            </div>
            <div class="d-flex justify-content-between">
              <div
                class="badge badge-pill badge-outline-primary"
                *ngIf="i === defaultAddress"
              >
                Default
              </div>
              <div *ngIf="i !== defaultAddress">&nbsp;</div>
              <div class="c-pointer" (click)="deleteAddress(i, $event)">
                <i class="simple-icon-trash"></i> Delete
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer h-90-px">
    <button class="btn btn-primary mb-0" (click)="Continue()">Add New</button>
  </div>
</ng-template>
<simple-notifications class="show-on-top"></simple-notifications>
<ng-template #newAddress>
  <div class="modal-header">
    <strong>Add New Address</strong>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeNewModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body h-85-vh">
    <div class="form-group">
      <label>Search Address</label>
      <input
        type="text"
        class="form-control"
        (keyup)="searchAddress($event)"
        [(ngModel)]="selectedFinalAddress"
        name="search place"
      />
    </div>
    <div *ngIf="possibleAddresses.length > 0">
      <div
        *ngFor="let address of possibleAddresses"
        (click)="addressSelect(address)"
        class="dropdown-item c-pointer"
      >
        <h3>{{ address.streetaddress }}</h3>
        <p>{{ address.city }}, {{ address.state }} {{ address.zipcode }}</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #confirmDelete>
  <div class="modal-body text-center">
    <h6 class="modal-title mb-4">Are you sure you want to delete?</h6>
    <button type="button" class="btn btn-outline-primary" (click)="confirm()">
      {{ "modal.yes" | translate }}
    </button>
    {{ " " }}
    <button type="button" class="btn btn-primary" (click)="decline()">
      {{ "modal.no" | translate }}
    </button>
  </div>
</ng-template>
