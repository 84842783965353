<ng-template #mainTemplate>
    <div class="modal-header h-90-px">
        <div class="form-group mb-4 d-flex align-items-center justify-content-between">
            <input id="searchStoreInputElm" type="text" class="form-control border-0" [(ngModel)]="storeSearchKey" name="Search Keyboard"
                placeholder="Search Store" />
            <button class="btn btn-outline-primary mb-0 border-0" (click)="searchStore()">
                <i class="simple-icon-magnifier"></i>
            </button>
        </div>
        <button type="button" class="close pull-right" aria-label="Close" (click)="mainModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body overflow-y-auto h-diff-190-vh">
        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <!-- <div class="form-group mb-4 d-flex align-items-center justify-content-between">
                        <input type="text" class="form-control mr-4" [(ngModel)]="storeSearchKey" name="Search Keyboard"
                            placeholder="Search Store" />
                        <button class="btn btn-primary mb-0" (click)="searchStore()">
                            Search
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="filteredStores.length > 0">
                <div class="d-flex flex-row mb-3 pb-3 border-bottom" *ngFor="let store of filteredStores">
                    <span (click)="goToStore(store)">
                        <img [src]="store.image" [alt]="store.name"
                            class="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall" />
                    </span>
                    <div class="pl-3 pr-2">
                        <span (click)="goToStore(store)">
                            <p class="font-weight-medium mb-0">{{ store.name }}</p>
                            <p class="text-muted mb-0 text-small">
                                {{ store.streetaddress }}
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="filteredStores.length === 0">
                No match found
            </div>
        </div>
    </div>
</ng-template>
<simple-notifications class="show-on-top"></simple-notifications>
<ng-template #confirmTemplate>
    <div class="modal-body text-center">
        <h6 class="modal-title mb-4">
            If you visit a different store, your cart will be cleared. Do you want to
            proceed?
        </h6>
        <button type="button" class="btn btn-outline-primary" (click)="confirm()">
            {{ "modal.yes" | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="decline()">
            {{ "modal.no" | translate }}
        </button>
    </div>
</ng-template>