import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop-info-alt',
  templateUrl: './shop-info-alt.component.html',
})
export class ShopInfoAltComponent  {
  @Input() storeInfo: any;
  constructor() {}

}
