<span class="position-absolute" style="right: 0">
    <!-- <button
    type="button"
    class="btn btn-xs btn-outline-primary"
    *ngIf="quantity == 0"
    disabled
  >
    -
  </button>
  <button
    type="button"
    class="btn btn-xs btn-outline-primary"
    *ngIf="quantity == 1"
    (click)="deleteBtnClick()"
  >
    -
  </button>
  <button
    type="button"
    class="btn btn-xs btn-outline-primary"
    *ngIf="quantity > 1"
    (click)="minusBtnClick()"
  >
    -
  </button>
  <button type="button" class="btn btn-xs text-default">{{ quantity }}</button>
  <button
    type="button"
    class="btn btn-xs btn-outline-primary"
    (click)="plusBtnClick()"
  >
    +
  </button> -->
    <div *ngIf="workingOnItem" class="border rounded pl-2 pr-2"
        style="box-shadow: -4px 7px 16px 0px; background-color: rgba(255, 255, 255, 0.9)">
        <span class="btn btn-outline-primary p-0 border-0 mb-1" (click)="minusBtnClick()">
            <i class="iconsminds-remove-basket" style="font-size: 19px;"></i>
        </span>
        <span style="font-size: 13px;" class="pt-1 pl-3 pr-3 mb-0 pb-1">{{ quantity }}</span>
        <span class="btn btn-outline-primary p-0 border-0 mb-1" style="margin-bottom: 6px!important;" (click)="plusBtnClick()">
            <i class="iconsminds-add-basket" style="font-size: 19px;"></i>
        </span>
    </div>
    <h1 *ngIf="!workingOnItem && quantity === 0"class="btn btn-outline-primary p-0 border-0" (click)="plusBtnClick()">
        <i class="iconsminds-add-basket" style="font-size: 23px;"></i>
    </h1>
    <span *ngIf="!workingOnItem && quantity > 0"class="btn btn-primary" style="padding: 3px 9px!important" (click)="plusBtnClick()">
        <b style="font-size: 12px;">{{quantity}}</b>
    </span>

</span>