// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl: 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS: false,
  adminRoot: '',
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: 'AIzaSyA8XPAhyF1r_dmQzZI_Zcnak0FMhobykzY',
    authDomain: 'shops-and-carts-develop.firebaseapp.com',
    databaseURL: 'https://shops-and-carts-develop.firebaseio.com',
    projectId: 'shops-and-carts-develop',
    storageBucket: 'shops-and-carts-develop.appspot.com',
    messagingSenderId: '769298931967',
    appId: '1:769298931967:web:99f785275b1a6b299f2cca',
    measurementId: 'G-5XJ9QM3JPP',
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibmV4dGdlbmFwcHMxNDYiLCJhIjoiY2tmZWg3cnUyMDIyNzJzbzdjd3ZiYzdicSJ9.jmBf4BLUx5c_shUDIf5KUA',
  }
};
