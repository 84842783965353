<button
  (click)="openModal(template)"
  id="button-basic"
  type="button"
  id="cartButton"
  class="header-icon btn btn-empty"
>
  <span class="dropdown-item c-pointer">Create Category</span>
</button>
<ng-template #template>
  <div *ngIf="!isModalOpen">
    <div class="modal-header">
      <h5>Create Category</h5>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hideModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div>
                <h5 class="mb-4">General Category Information</h5>
              </div>
              <div class="form-group mb-4">
                <label>Choose Business Category</label>
                <select
                  class="form-control"
                  [(ngModel)]="categoryModel.bizcat"
                  name="Business Category"
                >
                  <option value="g">Grocery</option>
                  <option value="r">Restaurant</option>
                </select>
              </div>
              <div class="form-group mb-4">
                <label>Category Name</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="categoryModel.name"
                  name="Categroy Name"
                />
              </div>

              <div class="form-group mb-4">
                <label
                  >Category Discount (Note - enter 0 if you dont have any
                  discounts in this category)</label
                >
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="categoryModel.discount"
                  name="Categroy Discount"
                />
              </div>

              <div class="form-group mb-4">
                <label>Description</label>
                <textarea
                  class="form-control"
                  [(ngModel)]="categoryModel.description"
                  name="Categroy Description"
                  id="categroyDesc"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div class="form-group mb-4">
                <label>Enter or paste image url of the category</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="categoryModel.image"
                  name="Category Image"
                />
              </div>

              <button class="btn btn-primary mb-0" (click)="Continue()">
                Create Category
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
