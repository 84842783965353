<button (click)="openModal(template)" id="button-basic" type="button" id="cartButton" class="header-icon btn btn-empty">
    <i class="iconsminds-shopping-cart"></i>
    <span class="count" *ngIf="totalItems > 0">{{ totalItems }}</span>
</button>
<ng-template #template>
    <div *ngIf="startCheckout">
        <div class="modal-header navbar">
            <h5>Your cart</h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-wizard-order [isLoggedIn]="isLoggedIn" (isLoggedInEmitter)="loggedInHandler($event)"
                (isOrderPlacedEmitter)="onItemUpdate($event)"></app-wizard-order>
        </div>
    </div>

    <div *ngIf="!startCheckout">
        <div class="modal-header navbar">
            <h5>Your Cart ({{ totalItems }} Items)</h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body max-h-70-vh overflow-y-auto" *ngIf="totalItems > 0">
            <div class="d-flex flex-row mb-1" *ngFor="let item of items">
                <img [alt]="item.name" [src]="item.image"
                    class="img-thumbnail border-0 rounded-circle m-2 list-thumbnail small align-self-center" />
                <div class="d-flex flex-grow-1 min-width-zero">
                    <div
                        class="card-body pl-1 pr-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                        <div class="min-width-zero d-flex align-items-center justify-content-between w-100">
                            <div>
                                <p (click)="testFn()" class="list-item-heading mb-1 text-default truncate">
                                    {{ item.name }} ({{ item.weight }})
                                </p>
                                <p class="mb-2 text-muted text-small">{{ item.brand }}</p>
                                <p class="mb-4">Price: ${{ item.saleprice }}</p>
                            </div>
                            <div>
                                <app-add-item source="cart" [item]="item" (updateItemEvent)="onItemUpdate(item)">
                                </app-add-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body d-flex justify-content-center align-items-center"
            *ngIf="!totalItems || totalItems === 0">
            Your cart is empty
        </div>
        <div class="modal-footer navbar justify-content-between">
            <span *ngIf="totalPrice">Subtotal - ${{ totalPrice }}</span>
            <span *ngIf="!totalPrice">Subtotal - $0</span>
            <button type="button" class="btn btn-xs btn-outline-primary" (click)="checkLogin()"
                [disabled]="!totalItems || totalItems === 0">
                Proceed to Checkout
            </button>
        </div>
    </div>
</ng-template>