import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-info-alt',
  templateUrl: './category-info-alt.component.html'
})
export class CategoryInfoAltComponent implements OnInit {
  rate = 4;
  constructor() { }

  ngOnInit(): void {
  }

}
