import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UtilsService } from 'src/app/data/utils.service';

@Component({
    selector: 'app-modal-process-checkout',
    templateUrl: './modal-process-checkout.component.html',
})
export class ModalProcessCheckoutComponent implements OnInit {
    modalRef: BsModalRef;
    items: any[];
    totalItems = 0;
    totalPrice = 0;
    searchKey = '';
    startCheckout = false;
    isLoggedIn = false;

    config = {
        animated: false,
        backdrop: true,
        ignoreBackdropClick: true,
    };
    qpMap = {};

    @Output()
    updateItemEvent: EventEmitter<any> = new EventEmitter();

    @Output() isLoggedInEmitter: EventEmitter<string> = new EventEmitter();

    constructor(
        private modalService: BsModalService,
        public utils: UtilsService
    ) { }

    ngOnInit() {
        this.utils.getQPMap().subscribe((value) => {
            this.qpMap = value;
            this.items = this.qpMap['totalCart'];
            this.totalItems = this.qpMap['totalQuantity'];
            this.totalPrice = this.qpMap['totalPrice'];
        });
    }

    onItemAdd() {
        this.utils.getQPMap().subscribe((value) => {
            this.qpMap = value;
            this.items = this.qpMap['totalCart'];
            this.totalItems = this.qpMap['totalQuantity'];
            this.totalPrice = this.qpMap['totalPrice'];
        });
    }

    onItemUpdate(event) {
        this.updateItemEvent.emit(event);
    }

    openModal(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(template, this.config);
    }

    hideModal(): void {
        this.startCheckout = false;
        this.modalRef.hide();
    }

    checkLogin() {
        this.startCheckout = true;
        if (localStorage.getItem('isLoggedIn') === 'true') {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    }

    orderPlacedHandler(event) {

    }
    // searchKeyUp(event: KeyboardEvent): void {
    //     if (event.key === 'Enter') {
    //         this.search();
    //     } else if (event.key === 'Escape') {
    //         const input = document.querySelector('.mobile-view');
    //         if (input && input.classList) {
    //             input.classList.remove('mobile-view');
    //         }
    //         this.searchKey = '';
    //     }
    // }

    // searchAreaClick(event): void {
    //     event.stopPropagation();
    // }

    // searchClick(event): void {
    //     if (window.innerWidth < environment.menuHiddenBreakpoint) {
    //         let elem = event.target;
    //         if (!event.target.classList.contains('search')) {
    //             if (event.target.parentElement.classList.contains('search')) {
    //                 elem = event.target.parentElement;
    //             } else if (
    //                 event.target.parentElement.parentElement.classList.contains('search')
    //             ) {
    //                 elem = event.target.parentElement.parentElement;
    //             }
    //         }

    //         if (elem.classList.contains('mobile-view')) {
    //             this.search();
    //             elem.classList.remove('mobile-view');
    //         } else {
    //             elem.classList.add('mobile-view');
    //         }
    //     } else {
    //         this.search();
    //     }
    //     event.stopPropagation();
    // }

    // search(): void {
    //     if (this.searchKey && this.searchKey.length > 1) {
    //         // this.router.navigate([this.adminRoot + '/#'], {
    //         //     queryParams: { key: this.searchKey.toLowerCase().trim() },
    //         // });
    //         // this.searchKey = '';
    //     }
    // }

    // @HostListener('document:click', ['$event'])
    // handleDocumentClick(event): void {
    //     const input = document.querySelector('.mobile-view');
    //     if (input && input.classList) {
    //         input.classList.remove('mobile-view');
    //     }
    //     this.searchKey = '';
    // }
}
