import { Component, TemplateRef, HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modal-search-location',
    templateUrl: './modal-search-location.component.html',
})
export class ModalSearchLocationComponent {
    modalRef: BsModalRef;
    items: any[];
    searchKey = '';

    constructor(private modalService: BsModalService) {
        this.items = Array(15).fill(0);
    }

    openModal(template: TemplateRef<any>): void {
        this.modalRef = this.modalService.show(template);
    }

    searchKeyUp(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.search();
        } else if (event.key === 'Escape') {
            const input = document.querySelector('.mobile-view');
            if (input && input.classList) {
                input.classList.remove('mobile-view');
            }
            this.searchKey = '';
        }
    }

    searchAreaClick(event): void {
        event.stopPropagation();
    }
    searchClick(event): void {
        if (window.innerWidth < environment.menuHiddenBreakpoint) {
            let elem = event.target;
            if (!event.target.classList.contains('search')) {
                if (event.target.parentElement.classList.contains('search')) {
                    elem = event.target.parentElement;
                } else if (
                    event.target.parentElement.parentElement.classList.contains('search')
                ) {
                    elem = event.target.parentElement.parentElement;
                }
            }

            if (elem.classList.contains('mobile-view')) {
                this.search();
                elem.classList.remove('mobile-view');
            } else {
                elem.classList.add('mobile-view');
            }
        } else {
            this.search();
        }
        event.stopPropagation();
    }

    search(): void {
        if (this.searchKey && this.searchKey.length > 1) {
            // this.router.navigate([this.adminRoot + '/#'], {
            //     queryParams: { key: this.searchKey.toLowerCase().trim() },
            // });
            // this.searchKey = '';
        }
    }

    @HostListener('document:click', ['$event'])
    handleDocumentClick(event): void {
        const input = document.querySelector('.mobile-view');
        if (input && input.classList) {
            input.classList.remove('mobile-view');
        }
        this.searchKey = '';
    }
}
