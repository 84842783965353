import { Component } from '@angular/core';

@Component ({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html'
})

export class DropdownSearchComponent {

    constructor() {}

}
