import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CommonModule } from '@angular/common';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TranslateModule } from '@ngx-translate/core';
import { ShopsListComponent } from './shops-list/shops-list.component';
import { CategoryInfoAltComponent } from './category-info-alt/category-info-alt.component';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { PopularProductsComponent } from './popular-products/popular-products.component';
import { ShopInfoAltComponent } from './shop-info-alt/shop-info-alt.component';
import { AddItemComponent } from './add-item/add-item.component';
import { ComponentsCarouselModule } from '../../components/carousel/components.carousel.module';

@NgModule({
    declarations: [
        ShopsListComponent,
        CategoryInfoAltComponent,
        ProductCategoriesComponent,
        CategoriesListComponent,
        ProductsListComponent,
        PopularProductsComponent,
        ShopInfoAltComponent,
        AddItemComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        PerfectScrollbarModule,
        RatingModule,
        ComponentsCarouselModule
    ],
    exports: [
        ShopsListComponent,
        CategoryInfoAltComponent,
        ProductCategoriesComponent,
        CategoriesListComponent,
        ProductsListComponent,
        PopularProductsComponent,
        ShopInfoAltComponent,
        AddItemComponent
    ]
})

export class ShopsContainersModule { }

