import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StoreService } from 'src/app/data/store.service';
import { UtilsService } from 'src/app/data/utils.service';

@Component({
    selector: 'app-search-store-modal',
    templateUrl: './search-store-modal.component.html',
    styles: [],
})

export class SearchStoreModalComponent implements OnInit {
    mainModalRef: BsModalRef;
    confirmModalRef: BsModalRef;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
        class: 'modal-right',
    };
    storeSearchKey = '';
    stores = [];
    filteredStores = [];
    totalCartItems = 0;
    qpMap = {};
    store: any;

    @ViewChild('mainTemplate', { static: true }) mainTemplate: TemplateRef<any>;
    @ViewChild('confirmTemplate', { static: true })
    confirmTemplate: TemplateRef<any>;

    @Output()
    updateItemEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        private modalService: BsModalService,
        private notifications: NotificationsService,
        private storeService: StoreService,
        private utils: UtilsService,
        public router: Router
    ) { }

    ngOnInit(): void {
        this.utils.getQPMap().subscribe((res) => {
            if (res !== undefined) {
                this.qpMap = res;
                this.totalCartItems = res.totalQuantity;
            }
        });

    }

    show(): void {
        this.mainModalRef = this.modalService.show(this.mainTemplate, this.config);
    }

    searchStore(): void {
        this.filteredStores = [];
        if (this.storeSearchKey.trim() !== '') {
            this.storeService.getStores().subscribe((res) => {
                this.stores = res.filter((x) => x.id !== localStorage.getItem('uid'));
                this.filteredStores = this.stores.filter((store) => {
                    return store.name.indexOf(this.storeSearchKey.trim()) > -1;
                });
            });
        } else {
            this.notifications.create(
                'Error',
                'Please enter few characters to search',
                NotificationType.Error,
                { theClass: 'outline', timeOut: 3000, showProgressBar: false }
            );
        }
    }

    goToStore(store: any): void {
        this.store = store;
        const prevStoreId = localStorage.getItem('storeid');
        if (this.totalCartItems > 0 && prevStoreId !== store.id) {
            this.confirmModalRef = this.modalService.show(this.confirmTemplate, {
                class: 'modal-lg',
            });
        } else {
            localStorage.setItem('storeid', store.id);
            localStorage.setItem('storename', store.name);
            localStorage.setItem('bizcat', store.bizcat);
            this.mainModalRef.hide();
            this.router.navigate(['/' + store.id]);
        }
    }

    confirm(): void {
        this.utils.setQPMap({});
        localStorage.setItem('storeid', this.store.id);
        localStorage.setItem('storename', this.store.name);
        localStorage.setItem('bizcat', this.store.bizcat);
        this.updateItemEvent.emit('cleared');
        this.router.navigate(['/' + this.store.id]);
        this.confirmModalRef.hide();
        this.mainModalRef.hide();
    }

    decline(): void {
        this.confirmModalRef.hide();
    }
}
