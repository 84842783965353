import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/data/user.service';
@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styles: [],
})
export class EditUserModalComponent implements OnInit {
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-right',
  };
  username = '';
  userId = '';

  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    public userService: UserService,
    private notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.username = localStorage.getItem('fullname');
    this.userId = localStorage.getItem('uid');
  }

  show(): void {
    this.modalRef = this.modalService.show(this.template, this.config);
  }

  async Continue() {
    if (
      this.username !== undefined &&
      this.username !== null &&
      this.username.trim() !== ''
    ) {
      this.updateUser();
    } else {
      this.notifications.create(
        'Error',
        'All fields marked * are mandatory',
        NotificationType.Error,
        { theClass: 'outline', timeOut: 3000, showProgressBar: false }
      );
    }
  }

  updateUser() {
    this.userService
      .updateUser(this.userId, this.username)
      .then(async () => {
        this.notifications.create(
          'Success',
          'User is updated successfully',
          NotificationType.Success,
          { theClass: 'outline', timeOut: 3000, showProgressBar: false }
        );
        localStorage.setItem('fullname', this.username);
      })
      .catch((err) => console.log(err));
  }
}
