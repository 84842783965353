import { Component, TemplateRef, ViewChild, HostListener, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html'
})

export class SideMenuComponent implements OnInit {

    @Output()
    sideMenuEvent: EventEmitter<string> = new EventEmitter();

    isOpenSwitcher = false;
    toggleClass = 'theme-colors';

    constructor() {}

    ngOnInit(): void { }

    handleMenuClick(item): void {
        this.sideMenuEvent.emit(item);
    }

    toggleSwitcher(event): void {
        this.isOpenSwitcher = !this.isOpenSwitcher;
        this.toggleClass = this.isOpenSwitcher
            ? 'theme-colors shown'
            : 'theme-colors hidden';
        event.stopPropagation();
    }

    @HostListener('document:click', ['$event'])
    handleDocumentClick(event): void {
        if (this.isOpenSwitcher
            && !event.target.closest('.side-menu')) {
            this.isOpenSwitcher = false;
            this.toggleClass = 'theme-colors hidden';
        }
    }
}
