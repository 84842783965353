<ng-template #mainTemplate>
  <div class="modal-header h-90-px">
    <h5>Search Product</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="mainModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body overflow-y-auto h-diff-190-vh">
    <div class="row">
      <div class="col-12">
        <div class="mb-4">
          <div
            class="form-group mb-4 d-flex align-items-center justify-content-between"
          >
            <input
              type="text"
              class="form-control mr-4"
              [(ngModel)]="productSearchKey"
              name="Search Keyboard"
              placeholder="Search Product"
            />
            <button class="btn btn-primary mb-0" (click)="searchProduct()">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="filteredProducts.length > 0">
        <div
          class="d-flex flex-row mb-3 pb-3 border-bottom"
          *ngFor="let product of filteredProducts"
        >
          <span>
            <img
              [src]="product.image"
              [alt]="product.name"
              class="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
            />
          </span>
          <div class="pl-3 pr-2">
              <p class="font-weight-medium mb-2">{{ product.name }}</p>
              <app-add-item [item]="product"></app-add-item>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="filteredProducts.length === 0">
        No match found
      </div>
    </div>
  </div>
</ng-template>
<simple-notifications class="show-on-top"></simple-notifications>
