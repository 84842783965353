<div class="row listing-card-container">
    <div class="col-sm-6 col-lg-6 col-xl-4 col-6 mb-4" *ngFor="let item of categoryInfo">
        <div class="card bg-dark text-white" (click)="goToCategoryProducts(item.id)">
            <img class="card-img h-200" [src]="item.image" alt="Card image cap">
            <div class="card-img-overlay">
                <p class="list-item-heading mb-5">{{item.name}}</p>
            </div>
        </div>
    </div>
</div>
