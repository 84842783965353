<nav class="navbar fixed-top">
    <div class="d-flex align-items-center navbar-left">
        <div class="position-relative d-inline-block mr-2">
            <!-- <button id="button-basic" type="button" id="menuButton"
            class="header-icon btn btn-empty menu-button-mobile d-xs-block d-sm-block"
            (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)">
            <i class="simple-icon-menu"></i>
        </button> -->
            <app-side-menu (sideMenuEvent)="handleSideMenuEvents($event)"></app-side-menu>
        </div>



        <div class="btn-group ml-2" dropdown>
            <div dropdownToggle class="dropdown-toggle">
                <i class="iconsminds-map-marker-2 d-inline-block"></i>
                Naperville
                <span class="caret"></span>
            </div>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)">
                    <a class="dropdown-item" href="javascript:;">{{ item.label }}</a>
                </li>
            </ul>
        </div>
    </div>

    <div>
    <!-- <div class="header-icons d-inline-block align-middle mr-2">
      <app-modal-search-shops></app-modal-search-shops>
    </div> -->
        <div class="position-relative d-inline-block mr-2">
            <app-modal-process-checkout #checkout (updateItemEvent)="onItemUpdate($event)"
                (isLoggedInEmitter)="loggedInHandler($event)"></app-modal-process-checkout>
        </div>
        <div class="position-relative d-inline-block mr-2">
            <app-dropdown-notification></app-dropdown-notification>
        </div>
        <div class="user d-inline-block mr-2" dropdown>
            <button dropdownToggle type="button" id="searchButton" class="header-icon btn btn-empty">
                <i class="simple-icon-magnifier"></i>
            </button>

            <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
                <a class="dropdown-item c-pointer" (click)="openStoreSearch()">Search Store</a>
                <a class="dropdown-item c-pointer" (click)="openProductSearch()">Search Product</a>
            </div>
        </div>
        <!-- <div class="position-relative d-inline-block mr-2">
      <app-dropdown-admin-menu></app-dropdown-admin-menu>
    </div> -->
        <!-- <div class="user d-inline-block mr-2" dropdown>
      <button
        dropdownToggle
        type="button"
        id="menuButton"
        class="header-icon btn btn-empty"
      >
        <i class="simple-icon-menu"></i>
      </button>

      <div
        class="dropdown-menu dropdown-menu-right mt-3"
        *dropdownMenu
        role="menu"
      >
        <app-modal-process-create-store></app-modal-process-create-store>
        <app-modal-process-create-category></app-modal-process-create-category>
        <app-modal-process-create-product></app-modal-process-create-product>
      </div>
    </div> -->
        <!-- <div class="position-relative d-inline-block mr-2" *ngIf="!isLoggedIn">
            <app-modal-process-login (isLoggedInEmitter)="loggedInHandler($event)"></app-modal-process-login>
        </div> -->

        <div class="user d-inline-block mr-2" dropdown *ngIf="isLoggedIn">
            <button class="btn btn-empty p-0" type="button" dropdownToggle>
                <span class="name">{{ displayName }}</span>
                <span>
                    <img alt="Profile Picture" src="/assets/img/profiles/l-1.jpg" />
                </span>
            </button>

            <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
                <a class="dropdown-item c-pointer" (click)="editUser()">Account</a>
                <a class="dropdown-item c-pointer" (click)="editAddresses()">My Addresses</a>
                <a class="dropdown-item c-pointer">Features</a>
                <a class="dropdown-item c-pointer">History</a>
                <a class="dropdown-item c-pointer">Support</a>
                <li class="divider dropdown-divider"></li>
                <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
            </div>
        </div>
    </div>
</nav>
<app-edit-user-modal #editUserModalRef></app-edit-user-modal>
<app-edit-addresses-modal #editAddressesModalRef></app-edit-addresses-modal>
<app-search-store-modal #searchStoreModalRef (updateItemEvent)="onItemUpdate($event)"></app-search-store-modal>
<app-search-product-modal #searchProductModalRef></app-search-product-modal>
<app-modal-process-login #loginModalRef (isLoggedInEmitter)="loggedInHandler($event)"></app-modal-process-login>