<div>
  <h1 class="p-0 m-0 font-weight-bold">{{ storeInfo?.name }}</h1>
  <p class="p-0 m-0">
    {{ storeInfo?.streetaddress }}, {{ storeInfo?.city }},
    {{ storeInfo?.state }} {{ storeInfo?.zipcode }}
  </p>
  <!-- <div class="text-zero top-right-button-container">
        <div dropdown>
            <button id="button-basic" dropdownToggle type="button" placement="bottom right"
                class="btn btn-outline-primary dropdown-toggle btn-lg"
                aria-controls="dropdown-basic">{{ 'pages.actions' | translate }} <span
                    class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item"
                        href="javascript:;">{{ 'pages.action' | translate }}</a></li>
                <li role="menuitem"><a class="dropdown-item"
                        href="javascript:;">{{ 'pages.another-action' | translate }}</a></li>
            </ul>
        </div>
    </div> -->
</div>
