<button
  (click)="openModal(template)"
  id="button-basic"
  type="button"
  id="cartButton"
  class="header-icon btn btn-empty"
>
  <span class="dropdown-item c-pointer">Create Product</span>
</button>
<ng-template #template>
  <div *ngIf="!isModalOpen">
    <div class="modal-header">
      <h5>Create Product</h5>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hideModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div>
                <h5 class="mb-4">General Product Information</h5>
              </div>

              <!-- <div class="form-group mb-4">
                <label>Choose Product Category?</label>
                <select
                  class="form-control"
                  [(ngModel)]="productModel.prodcat"
                  name="Product Category"
                >
                  <option value="g">Grocery</option>
                  <option value="r">Restaurant</option>
                </select>
              </div> -->

              <div class="form-group mb-4">
                <label>Choose Business Category</label>
                <select
                  class="form-control"
                  [(ngModel)]="productModel.bizcat"
                  name="Business Category"
                  (change)="chooseBusinessCategory()"
                >
                  <option value="g">Grocery</option>
                  <option value="r">Restaurant</option>
                </select>
              </div>

              <div class="form-group mb-4">
                <label>Choose Product Category</label>
                <select
                  class="form-control"
                  [(ngModel)]="productModel.prodcat"
                  name="Product Category"
                >
                  <option
                    [value]="category.id"
                    *ngFor="let category of productCategories"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>

              <div class="form-group mb-4">
                <label>Product Name</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="productModel.name"
                  name="Product Name"
                />
              </div>

              <div class="form-group mb-4">
                <label>Regular Price (This price will be crossed out)</label>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="productModel.regularprice"
                  name="Product Actual Price"
                />
              </div>

              <div class="form-group mb-4">
                <label>Sale Price (Actual price)</label>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="productModel.saleprice"
                  name="Product Sale Price"
                />
              </div>

              <div class="form-group mb-4">
                <label>Brand</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="productModel.brand"
                  name="Product Brand"
                />
              </div>

              <div class="form-group mb-4">
                <label>Product weight (ex: 2 lbs / 250 grams / etc)</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="productModel.weight"
                  name="Product Weight"
                />
              </div>

              <div>
                <h5 class="mb-4">Product Availability</h5>
              </div>

              <div class="mb-4">
                <div class="custom-control custom-checkbox mb-4">
                  <input
                    type="checkbox"
                    id="deliveryCheck"
                    class="custom-control-input"
                    [(ngModel)]="productModel.isinstock"
                    name="isInStock"
                  /><label for="deliveryCheck" class="custom-control-label"
                    >Is Product In Stock</label
                  >
                </div>
              </div>

              <div>
                <h5 class="mb-4">Product Description</h5>
              </div>

              <div class="form-group mb-4">
                <label>Description</label>
                <textarea
                  class="form-control"
                  [(ngModel)]="productModel.description"
                  name="Product Description"
                  id="productDesc"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div class="form-group mb-4">
                <label>Enter or paste image url of the product</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="productModel.image"
                  name="Product Image"
                />
              </div>

              <button class="btn btn-primary mb-0" (click)="Continue()">
                Create Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
