<ng-template #template>
  <div class="modal-header h-90-px">
    <h5>Edit User</h5>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body overflow-y-auto h-diff-190-vh">
    <div class="row">
      <div class="col-12">
        <div class="mb-4">
          <div>
            <h5 class="mb-4">Basic Information</h5>
          </div>

          <div class="form-group mb-4">
            <label>Name<span class="required"> *</span></label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="username"
              name="User Name"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer h-90-px">
    <button
      class="btn btn-primary mb-0"
      (click)="Continue()"
    >
      Update
    </button>
  </div>
</ng-template>
<simple-notifications class="show-on-top"></simple-notifications>
