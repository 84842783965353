<div class="{{toggleClass}} cart">
    <a (click)="toggleSwitcher($event)" class="c-pointer theme-button">
        <i class="iconsminds-shopping-cart"></i>
    </a>
    <div class="cart-body">
        <div class="p-4 ">
            <p class="text-muted mb-2">Cart Items</p>
        </div>
        <app-footer>Proceed</app-footer>
    </div>
</div>