import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component ({
  selector: 'app-shops-list',
  templateUrl: './shops-list.component.html'
})

export class ShopsListComponent implements OnInit {
    constructor(public router: Router) {}

    ngOnInit(): void {}

    goToShops(): void {
        this.router.navigate(['/account/shop']);
    }
}

