<button
  (click)="openModal(template)"
  id="button-basic"
  type="button"
  id="cartButton"
  class="header-icon btn btn-empty"
>
  <span class="dropdown-item c-pointer">Create Store</span>
</button>
<ng-template #template>
  <div *ngIf="!isModalOpen">
    <div class="modal-header">
      <h5>Create Store</h5>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="hideModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div>
                <h5 class="mb-4">Basic Info</h5>
              </div>
              <div class="form-group mb-4">
                <label>What type of store are you setting up?</label>
                <select
                  class="form-control"
                  [(ngModel)]="storeModel.type"
                  name="Store Type"
                >
                  <option value="r">Retail</option>
                  <option value="d">Distribution</option>
                </select>
              </div>

              <div class="form-group mb-4">
                <label>What is the business category?</label>
                <select
                  class="form-control"
                  [(ngModel)]="storeModel.bizcat"
                  name="Store Category"
                >
                  <option value="g">Grocery</option>
                  <option value="r">Restaurant</option>
                </select>
              </div>

              <div class="form-group mb-4">
                <label
                  >Enter or paste image url of the store (logo / store
                  picture)</label
                >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="storeModel.image"
                  name="Store Image"
                />
              </div>

              <div>
                <h5 class="mb-4">General Info</h5>
              </div>

              <div class="form-group mb-4">
                <label>Name of your store</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="storeModel.name"
                  name="Store Name"
                />
              </div>

              <div class="form-group mb-4">
                <label>About your store</label>
                <textarea
                  class="form-control"
                  [(ngModel)]="storeModel.about"
                  name="About Store"
                  id="about"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div class="form-group mb-4">
                <label>Street Address</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="storeModel.streetaddress"
                  name="Store Address"
                />
              </div>

              <div class="form-group mb-4">
                <label>City</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="storeModel.city"
                  name="Store City"
                />
              </div>

              <div class="form-group mb-4">
                <label>State</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="storeModel.state"
                  name="Store State"
                />
              </div>

              <div class="form-group mb-4">
                <label>Zipcode</label>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="storeModel.zipcode"
                  name="Store Zipcode"
                />
              </div>

              <div>
                <h5 class="mb-4">Pickup/Delivery Options</h5>
              </div>

              <div class="mb-4">
                <div class="custom-control custom-checkbox mb-4">
                  <input
                    type="checkbox"
                    id="deliveryCheck"
                    class="custom-control-input"
                    [(ngModel)]="storeModel.isdelivery"
                    name="isDelivery"
                  /><label for="deliveryCheck" class="custom-control-label"
                    >Do you offer delivery services for your products?</label
                  >
                </div>
              </div>

              <div class="mb-4">
                <div class="custom-control custom-checkbox mb-4">
                  <input
                    type="checkbox"
                    id="curbsideCheck"
                    class="custom-control-input"
                    [(ngModel)]="storeModel.iscurbsidepickup"
                    name="isCurbSide"
                  /><label for="curbsideCheck" class="custom-control-label"
                    >Do you offer curb side pickup services for your
                    products?</label
                  >
                </div>
              </div>

              <div>
                <h5 class="mb-4">Payment Options</h5>
              </div>

              <div class="mb-4">
                <div class="custom-control custom-checkbox mb-4">
                  <input
                    type="checkbox"
                    id="onlineCheck"
                    class="custom-control-input"
                    [(ngModel)]="storeModel.ispayonline"
                    name="isOnline"
                  /><label for="onlineCheck" class="custom-control-label"
                    >Can the customers pay for your products online?</label
                  >
                </div>
              </div>

              <div class="mb-4">
                <div class="custom-control custom-checkbox mb-4">
                  <input
                    type="checkbox"
                    id="creditCheck"
                    class="custom-control-input"
                    [(ngModel)]="storeModel.iscredit"
                    name="isCredit"
                  /><label for="creditCheck" class="custom-control-label"
                    >Can your customers place orders without paying for them
                    online. (Only pick this option if you know the
                    customer)</label
                  >
                </div>
              </div>

              <button class="btn btn-primary mb-0" (click)="Continue()">
                Create Store
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
