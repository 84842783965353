<div dropdown>
    <button id="button-basic" dropdownToggle type="button" id="notificationButton" class="header-icon btn btn-empty">
        <i class="simple-icon-bell"></i>
        <span class="count">3</span>
    </button>
    <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="notificationDropdown">
        <perfect-scrollbar>
            <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                    <img src="/assets/img/profiles/l-2.jpg" alt="Notification Image"
                        class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                    <a href="javascript:;">
                        <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                        <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                </div>
            </div>
            <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                    <img src="/assets/img/notifications/thumb-1.jpg" alt="Notification Image"
                        class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                    <a href="javascript:;">
                        <p class="font-weight-medium mb-1">1 item is out of stock!</p>
                        <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                </div>
            </div>
            <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;">
                    <img src="/assets/img/notifications/thumb-2.jpg" alt="Notification Image"
                        class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                    <a href="javascript:;">
                        <p class="font-weight-medium mb-1">New order received! It is total $147,20.</p>
                        <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                </div>
            </div>
            <div class="d-flex flex-row mb-3 pb-3 ">
                <a href="javascript:;">
                    <img src="/assets/img/notifications/thumb-3.jpg" alt="Notification Image"
                        class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                </a>
                <div class="pl-3">
                    <a href="javascript:;">
                        <p class="font-weight-medium mb-1">3 items just added to wish list by a user!
                        </p>
                        <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                    </a>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>