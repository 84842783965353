import { Component } from '@angular/core';
import ticketItems, { ITicket } from '../../../data/tickets';

@Component ({
  selector: 'app-dropdown-cart',
  templateUrl: './dropdown-cart.component.html'
})

export class DropdownCartComponent {

    data: ITicket[] = ticketItems;
    constructor() {}

}
