import { Component, OnInit, OnDestroy, HostListener, ViewChild, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { ModalProcessCheckoutComponent } from '../modal-process-checkout/modal-process-checkout.component';
import { EditUserModalComponent } from './edit-user-modal/edit-user-modal.component';
import { SearchStoreModalComponent } from './search-store-modal/search-store-modal.component';
import { SearchProductModalComponent } from './search-product-modal/search-product-modal.component';
import { EditAddressesModalComponent } from './edit-addresses-modal/edit-addresses-modal.component';
import { ModalProcessLoginComponent } from '../modal-process-login/modal-process-login.component';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
})

export class TopnavComponent implements OnInit, OnDestroy {
    buyUrl = environment.buyUrl;
    adminRoot = environment.adminRoot;
    sidebar: ISidebar;
    subscription: Subscription;
    displayName = 'Sarah Cortney';
    languages: Language[];
    currentLanguage: string;
    isSingleLang;
    isFullScreen = false;
    isDarkModeActive = false;
    searchKey = '';
    isLoggedIn = false;

    @ViewChild(ModalProcessCheckoutComponent)
    checkout: ModalProcessCheckoutComponent;

    @ViewChild('loginModalRef', { static: true })
    loginModalRef: ModalProcessLoginComponent;

    @ViewChild('editUserModalRef', { static: true })
    editUserModalRef: EditUserModalComponent;

    @ViewChild('editAddressesModalRef', { static: true })
    editAddressesModalRef: EditAddressesModalComponent;

    @ViewChild('searchStoreModalRef', { static: true })
    searchStoreModalRef: SearchStoreModalComponent;

    @ViewChild('searchProductModalRef', { static: true })
    searchProductModalRef: SearchProductModalComponent;

    @Output()
    updateItemEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        private sidebarService: SidebarService,
        private authService: AuthService,
        private langService: LangService
    ) {
        if (localStorage.getItem('isLoggedIn') === 'true') {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = true;
        }
        this.languages = this.langService.supportedLanguages;
        this.currentLanguage = this.langService.languageShorthand;
        this.isSingleLang = this.langService.isSingleLang;
        this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    }

    editUser(): void {
        this.editUserModalRef.show();
    }

    editAddresses(): void {
        this.editAddressesModalRef.show();
    }

    openStoreSearch(): void {
        this.searchStoreModalRef.show();
    }

    openProductSearch(): void {
        this.searchProductModalRef.show();
    }

    openLogin(): void {
        if (!this.isLoggedIn) {
            this.loginModalRef.openModal();
        }
    }

    onItemAdd(event): void {
        this.checkout.onItemAdd();
    }

    onItemUpdate(event): void {
        this.updateItemEvent.emit(event);
    }

    handleSideMenuEvents(event): void {
        if (event === 'login') {
            this.openLogin();
        }
    }

    onDarkModeChange(event): void {
        let color = getThemeColor();
        if (color.indexOf('dark') > -1) {
            color = color.replace('dark', 'light');
        } else if (color.indexOf('light') > -1) {
            color = color.replace('light', 'dark');
        }
        setThemeColor(color);
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }

    fullScreenClick(): void {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    }

    @HostListener('document:fullscreenchange', ['$event'])
    handleFullscreen(event): void {
        if (document.fullscreenElement) {
            this.isFullScreen = true;
        } else {
            this.isFullScreen = false;
        }
    }

    onLanguageChange(lang): void {
        this.langService.language = lang.code;
        this.currentLanguage = this.langService.languageShorthand;
    }

    async ngOnInit(): Promise<void> {
        if (localStorage.getItem('isLoggedIn') === 'true') {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
        if (await this.authService.getUser()) {
            this.displayName = await this.authService.getUser().then((user) => {
                return user.displayName;
            });
        }
        this.subscription = this.sidebarService.getSidebar().subscribe(
            (res) => {
                this.sidebar = res;
            },
            (err) => {
                console.error(`An error occurred: ${err.message}`);
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    menuButtonClick = (
        e: { stopPropagation: () => void },
        menuClickCount: number,
        containerClassnames: string
    ) => {
        if (e) {
            e.stopPropagation();
        }

        setTimeout(() => {
            const event = document.createEvent('HTMLEvents');
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }, 350);

        this.sidebarService.setContainerClassnames(
            ++menuClickCount,
            containerClassnames,
            this.sidebar.selectedMenuHasSubItems
        );
    };

    mobileMenuButtonClick = (
        event: { stopPropagation: () => void },
        containerClassnames: string
    ) => {
        if (event) {
            event.stopPropagation();
        }
        this.sidebarService.clickOnMobileMenu(containerClassnames);
    };

    onSignOut(): void {
        localStorage.clear();
        this.isLoggedIn = false;
        //   this.authService.signOut().subscribe(() => {
        //     this.router.navigate([this.adminRoot]);
        //   });
    }

    loggedInHandler(event) {
        if (event === 'true') {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = true;
        }
    }

    //   searchKeyUp(event: KeyboardEvent): void {
    //     if (event.key === 'Enter') {
    //       this.search();
    //     } else if (event.key === 'Escape') {
    //       const input = document.querySelector('.mobile-view');
    //       if (input && input.classList) {
    //         input.classList.remove('mobile-view');
    //       }
    //       this.searchKey = '';
    //     }
    //   }

    //   searchAreaClick(event): void {
    //     event.stopPropagation();
    //   }
    //   searchClick(event): void {
    //     if (window.innerWidth < environment.menuHiddenBreakpoint) {
    //       let elem = event.target;
    //       if (!event.target.classList.contains('search')) {
    //         if (event.target.parentElement.classList.contains('search')) {
    //           elem = event.target.parentElement;
    //         } else if (
    //           event.target.parentElement.parentElement.classList.contains('search')
    //         ) {
    //           elem = event.target.parentElement.parentElement;
    //         }
    //       }

    //       if (elem.classList.contains('mobile-view')) {
    //         this.search();
    //         elem.classList.remove('mobile-view');
    //       } else {
    //         elem.classList.add('mobile-view');
    //       }
    //     } else {
    //       this.search();
    //     }
    //     event.stopPropagation();
    //   }

    //   search(): void {
    //     if (this.searchKey && this.searchKey.length > 1) {
    //       this.router.navigate([this.adminRoot + '/#'], {
    //         queryParams: { key: this.searchKey.toLowerCase().trim() },
    //       });
    //       this.searchKey = '';
    //     }
    //   }

    //   @HostListener('document:click', ['$event'])
    //   handleDocumentClick(event): void {
    //     const input = document.querySelector('.mobile-view');
    //     if (input && input.classList) {
    //       input.classList.remove('mobile-view');
    //     }
    //     this.searchKey = '';
    //   }
}
