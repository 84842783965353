import { Injectable } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  myStoreRef: AngularFirestoreCollection<Store>;

  constructor(public Afs: AngularFirestore) {}

  public async getStoreInfo(shopId) {
    if (shopId) {
      this.myStoreRef = this.Afs.collection<Store>('stores');
      return this.myStoreRef
        .doc(shopId)
        .snapshotChanges()
        .pipe(
          map((res: any) => {
            const result = res.payload.data() as Store;
            if (result) {
              result.id = res.payload.id;
            }
            return result;
          })
        );
    }
  }

  public async getUserLocalStores(city) {
    this.myStoreRef = this.Afs.collection('stores', (ref) =>
      ref.where('city', '==', city)
    );
    return this.myStoreRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data: any = dataItems.payload.doc.data();
          const id = dataItems.payload.doc.id;
          // this.storesNearBy.push({ id, ...data });
          return { id, ...data };
        })
      )
    );
  }

  getStores() {
    this.myStoreRef = this.Afs.collection<Store>('stores');
    return this.myStoreRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  public async getStoreByUser(id: string) {
    this.myStoreRef = this.Afs.collection('stores', (ref) =>
      ref.where('owners', 'array-contains', id)
    );
    return this.myStoreRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data();
          const id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  public async createStore(result) {
    this.myStoreRef = this.Afs.collection<Store>('stores');
    await this.myStoreRef.add({ ...result }).then((snapshot) => {
      console.log(snapshot);
    });
  }

  public async updateStore(id, result) {
    this.myStoreRef = this.Afs.collection<Store>('stores');
    await this.myStoreRef.doc(id).set({ ...result });
  }

  public async deleteStore(id) {
    this.myStoreRef = this.Afs.collection<Store>('stores');
    await this.myStoreRef.doc(id).delete();
  }

  public async assignOwner(id, users) {
    this.myStoreRef = this.Afs.collection<Store>('stores');
    await this.myStoreRef.doc(id).update({ owners: users });
  }

  getStoreParams(si: any) {
    const uid = localStorage.getItem('uid');
    return {
      uid: uid,
      name: si.name,
      about: si.about,
      streetaddress: si.streetaddress,
      city: si.city,
      state: si.state,
      zipcode: si.zipcode,
      iscurbsidepickup: si.iscurbsidepickup || false,
      isdelivery: si.isdelivery || false,
      iscredit: si.iscredit || false,
      ispayonline: si.ispayonline || false,
      categoriescount: 0,
      productscount: 0,
      type: si.type,
      bizcat: si.bizcat,
      image: si.image,
      owners: [],
    };
  }
}

export class Store {
  constructor() {}
  id: string;
  city: string;
  iscredit: boolean;
  iscurbsidepickup: boolean;
  isdelivery: boolean;
  ispayonline: boolean;
  name: string;
  about: string;
  state: string;
  streetaddress: string;
  zipcode: string;
  categoriescount: number;
  productscount: number;
  bizcat: string;
  type: string;
  image: '';
}
