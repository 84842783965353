import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ProductService } from 'src/app/data/product.service';
import { AddItemComponent } from '../add-item/add-item.component';

@Component({
  selector: 'app-popular-products',
  templateUrl: './popular-products.component.html',
})
export class PopularProductsComponent implements OnInit {
  data = [];
  @Output()
  addItemEvent: EventEmitter<any> = new EventEmitter();

  @ViewChildren(AddItemComponent) addItem: QueryList<AddItemComponent>;

  constructor(private productService: ProductService) {}

  ngOnInit() {
    this.getPopularProducts();
  }

  getPopularProducts() {
    this.productService.getPopularProducts().subscribe((productData) => {
      this.data = productData;
    });
  }

  onItemAdd(event) {
    this.addItemEvent.emit(event);
  }

  onItemUpdate(event) {
    this.addItem.forEach((child) => {
      child.handleUpdateItem(event);
    });
  }
}
