<div class="card-body p-1">
  <aw-wizard [navBarLayout]="'large-empty-symbols'" *ngIf="!isLoggedIn">
    <aw-wizard-step stepTitle="Login" style="min-height: 230px">
      <ng-template awWizardStepSymbol>
        <i class="simple-icon-login"></i>
      </ng-template>
      <div>
        <div class="modal-body">
          <label class="form-group has-float-label">
            <input
              class="form-control"
              type="text"
              placeholder=""
              required
              #username="ngModel"
              name="username"
              [(ngModel)]="usernameModel"
            />
            <span>{{ "user.username" | translate }}</span>
            <div *ngIf="!username.valid && isSubmitted" class="invalid-tooltip">
              Fullname is required!
            </div>
          </label>

          <label class="form-group has-float-label">
            <input
              class="form-control"
              type="tel"
              required
              #phone="ngModel"
              name="phone"
              [(ngModel)]="phoneModel"
            />
            <span>{{ "user.phone" | translate }}</span>
            <div *ngIf="!phone.valid && isSubmitted" class="invalid-tooltip">
              Phone is required!
            </div>
          </label>

          <label class="form-group has-float-label" *ngIf="isOtpSent">
            <input
              class="form-control"
              type="text"
              placeholder=""
              required
              #otp="ngModel"
              name="otp"
              [(ngModel)]="otpModel"
            />
            <span>{{ "user.otp" | translate }}</span>
            <div *ngIf="!otp.valid && isOtpSubmitted" class="invalid-tooltip">
              OTP is required!
            </div>
            <div
              *ngIf="otp.valid && isOtpSubmitted && isWrongOtp"
              class="invalid-tooltip"
            >
              Invalid OTP!
            </div>
            <div
              *ngIf="otp.valid && isOtpSubmitted && isExpiredOtp"
              class="invalid-tooltip"
            >
              OTP is expired!
            </div>
          </label>
        </div>
        <div class="modal-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div id="login-btn"></div>
            <app-state-button
              *ngIf="!isOtpSent"
              [btnClass]="'btn btn-primary btn-lg btn-shadow'"
              [currentState]="buttonState"
              [isDisabled]="buttonDisabled"
              (click)="getOtp()"
            >
              {{ "user.otp-button" | translate | uppercase }}
            </app-state-button>
            <app-state-button
              *ngIf="isOtpSent"
              [btnClass]="'btn btn-primary btn-lg btn-shadow'"
              [currentState]="buttonState"
              [isDisabled]="buttonDisabled"
              (click)="confirmOtp()"
            >
              {{ "user.login-button" | translate | uppercase }}
            </app-state-button>
          </div>
        </div>
      </div>
    </aw-wizard-step>
  </aw-wizard>
  <aw-wizard [navBarLayout]="'large-empty-symbols'" *ngIf="isLoggedIn">
    <aw-wizard-step stepTitle="Personal Info" style="min-height: 230px">
      <ng-template awWizardStepSymbol>
        <i class="simple-icon-user"></i>
      </ng-template>
      <div class="mt-4">
        <div class="form-group">
          <label>Goods Delivery</label>
          <select
            class="form-control"
            [(ngModel)]="selectedDelivery"
            name="Goods Delivery"
          >
            <option value="home">Home Delivery</option>
            <option value="store">At Store</option>
            <option value="curbside">Curbside</option>
          </select>
        </div>
        <div
          class="form-group"
          *ngIf="selectedDelivery !== '' && selectedDelivery !== 'curbside'"
        >
          <label>Delivery Address</label>
          <select
            class="form-control"
            [(ngModel)]="selectedAddress"
            name="Delivery Address"
            (change)="onAddressChange()"
          >
            <option
              [value]="addr.fullAddress"
              *ngFor="let addr of addressArray"
            >
              {{ addr.fullAddress }}
            </option>
          </select>
        </div>
        <!-- <div class="form-group" *ngIf="selectedAddress === 'Add New Address'">
          <label>Search Address</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="selectedFinalAddress"
            name="search place"
          />
        </div> -->
        <div class="form-group" *ngIf="selectedFinalAddress.length > 0">
          <input
            type="checkbox"
            class="mr-2"
            name="save Address"
            id="saveAddress"
            [(ngModel)]="isSaveAddress"
          />
          <label for="saveAddress">Do you want to save this address?</label>
        </div>
      <!--  <div
          *ngIf="
            selectedFinalAddress.length > 0 && possibleAddresses.length > 0
          "
        >
          <div
            *ngFor="let address of possibleAddresses"
            (click)="addressSelect(address)"
            class="dropdown-item c-pointer"
          >
            <h3>{{ address.streetaddress }}</h3>
            <p>{{ address.city }}, {{ address.state }} {{ address.zipcode }}</p>
          </div>
        </div> -->
        <div class="form-group">
          <label>Payment Option</label>
          <select
            class="form-control"
            [(ngModel)]="selectedPayment"
            name="Payment Option"
          >
            <option value="pod">Pay on Goods Delivery</option>
          </select>
        </div>
        <div class="form-group">
          <label>Special Instructions for Seller</label>
          <textarea
            class="form-control"
            name="specialInstruction"
            cols="30"
            rows="4"
            [(ngModel)]="specialInstruction"
          ></textarea>
        </div>
      </div>
      <div class="text-center">
        <button
          type="button"
          [disabled]="
            selectedDelivery.length === 0 ||
            selectedPayment.length === 0 ||
            (selectedDelivery === 'home' &&
              selectedAddress === 'Add New Address' &&
              selectedFinalAddress.length === 0) ||
            (selectedDelivery === 'store' &&
              selectedAddress === 'Add New Address' &&
              selectedFinalAddress.length === 0) ||
            (selectedDelivery === 'home' && selectedAddress.length === 0) ||
            (selectedDelivery === 'store' && selectedAddress.length === 0)
          "
          class="btn btn-primary"
          (click)="placeOrder()"
          awNextStep
        >
          Place Order
        </button>
      </div>
    </aw-wizard-step>
    <aw-wizard-completion-step
      stepTitle="{{ 'wizard.done' | translate }}"
      style="min-height: 230px"
    >
      <ng-template awWizardStepSymbol>
        <i class="simple-icon-check"></i>
      </ng-template>
      <div
        class="text-center complete-message-centered"
        *ngIf="orderStatus === 'success'"
      >
        <h2 class="mb-2">{{ "wizard.content-thanks" | translate }}</h2>
        <p>{{ "wizard.orderplaced" | translate }}</p>
      </div>
      <div
        class="text-center complete-message-centered"
        *ngIf="orderStatus === 'failure'"
      >
        <h2 class="mb-2">{{ "wizard.content-sorry" | translate }}</h2>
        <p>{{ "wizard.ordernotplaced" | translate }}</p>
      </div>
    </aw-wizard-completion-step>
  </aw-wizard>
</div>
<ng-template #newAddress>
  <div class="modal-header">
    <strong>Add New Address</strong>
  </div>

  <div class="modal-body h-85-vh">
    <div class="form-group" *ngIf="selectedAddress === 'Add New Address'">
      <label>Search Address</label>
      <input
        type="text"
        class="form-control"
        (keyup)="searchAddress($event)"
        [(ngModel)]="selectedFinalAddress"
        name="search place"
      />
    </div>
    <div
      *ngIf="selectedFinalAddress.length > 0 && possibleAddresses.length > 0"
    >
      <div
        *ngFor="let address of possibleAddresses"
        (click)="addressSelect(address)"
        class="dropdown-item c-pointer"
      >
        <h3>{{ address.streetaddress }}</h3>
        <p>{{ address.city }}, {{ address.state }} {{ address.zipcode }}</p>
      </div>
    </div>
  </div>
</ng-template>
