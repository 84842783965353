import { Component, TemplateRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/shared/auth.service';
import { UserService } from 'src/app/data/user.service';

@Component({
    selector: 'app-modal-process-login',
    templateUrl: './modal-process-login.component.html',
})
export class ModalProcessLoginComponent {
    @Output() isLoggedInEmitter: EventEmitter<string> = new EventEmitter();

    usernameModel = 'Demo User';
    phoneModel = '919445192962';

    buttonDisabled = false;
    buttonState = '';
    isSubmitted = false;
    isOtpSubmitted = false;
    isOtpSent = false;
    isWrongOtp = false;
    isExpiredOtp = false;
    confirmation: any;
    otpModel = '';
    modalRef: BsModalRef;

    config = {
        animated: false,
        backdrop: true,
        ignoreBackdropClick: true,
    };

    @ViewChild('template', { static: true }) template: TemplateRef<any>;

    constructor(
        private modalService: BsModalService,
        private authService: AuthService,
        private notifications: NotificationsService,
        private userService: UserService
    ) { }

    openModal(): void {
        this.modalRef = this.modalService.show(this.template, this.config);
    }

    hideModal(): void {
        this.modalRef.hide();
    }

    getOtp(): void {
        this.isSubmitted = true;
        if (
            this.usernameModel === undefined ||
            this.usernameModel === null ||
            this.usernameModel.trim() === '' ||
            this.phoneModel === undefined ||
            this.phoneModel === null ||
            this.phoneModel.trim() === '' ||
            this.buttonDisabled
        ) {
            return;
        }

        this.buttonDisabled = true;
        this.buttonState = 'show-spinner';

        this.authService
            .signInWithPhone(`+${this.phoneModel}`, 'login-btn')
            .then((result) => {
                this.confirmation = result;
                this.isOtpSent = true;
                this.buttonDisabled = false;
                this.buttonState = '';
            })
            .catch((error) => {
                console.log(error);
                this.buttonDisabled = false;
                this.buttonState = '';
                this.notifications.create(
                    'Error',
                    error.message,
                    NotificationType.Bare,
                    { theClass: 'outline primary', timeOut: 6000, showProgressBar: false }
                );
            });
    }

    confirmOtp(): void {
        this.isOtpSubmitted = true;
        if (
            this.otpModel === undefined ||
            this.otpModel === null ||
            this.otpModel.trim() === '' ||
            this.buttonDisabled
        ) {
            return;
        }

        this.buttonDisabled = true;
        this.buttonState = 'show-spinner';
        this.confirmation
            .confirm(this.otpModel)
            .then((result) => {
                this.buttonDisabled = false;
                this.buttonState = '';
                this.userService.createUser(result.user.uid, {
                    fullname: this.usernameModel,
                    created_on: new Date().toLocaleString(),
                    role: 'user'
                });
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('uid', result.user.uid);
                localStorage.setItem('fullname', this.usernameModel);
                localStorage.setItem('phone', this.phoneModel);
                this.isLoggedInEmitter.emit('true');
                this.modalRef.hide();
            })
            .catch((error) => {
                console.log(error);
                if (error.code === 'auth/invalid-verification-code') {
                    this.isWrongOtp = true;
                } else if (error.code === 'auth/code-expired') {
                    this.isExpiredOtp = true;
                }
                this.buttonDisabled = false;
                this.buttonState = '';
            });
    }
}
