import { Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, StoreService } from 'src/app/data/store.service';
import { UtilsService } from 'src/app/data/utils.service';

@Component({
  selector: 'app-modal-process-create-store',
  templateUrl: './modal-process-create-store.component.html',
})
export class ModalProcessCreateStoreComponent {
  modalRef: BsModalRef;
  isModalOpen = false;
  config = {
    animated: false,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-lg',
  };

  storeModel = new Store();

  constructor(
    private modalService: BsModalService,
    public utils: UtilsService,
    private storeService: StoreService
  ) {}

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, this.config);
  }

  hideModal(): void {
    this.isModalOpen = false;
    this.modalRef.hide();
  }

  async Continue() {
    if (this.storeModel) {
      const p = this.storeService.getStoreParams(this.storeModel);
      if (
        p.type &&
        p.bizcat &&
        p.name &&
        p.about &&
        p.streetaddress &&
        p.city &&
        p.state &&
        p.zipcode
      ) {
        this.createStore(p);
      }
    }
  }

  createStore(params) {
    this.storeService
      .createStore(params)
      .then(async () => {
        alert('Added New Store Successfully');
        this.storeModel = {
          id: '',
          city: '',
          iscredit: false,
          iscurbsidepickup: false,
          isdelivery: false,
          ispayonline: false,
          name: '',
          about: '',
          state: '',
          streetaddress: '',
          zipcode: '',
          bizcat: '',
          type: '',
          image: '',
          categoriescount: 0,
          productscount: 0,
        };
      })
      .catch((err) => console.log(err));
  }

  //   updateStore(params) {
  //     const changedParams = this.getChangedParams(params);
  //     if (Object.keys(changedParams).length > 0) {
  //       this.storeService
  //         .updateStore(this.utils.userInfo.id, changedParams)
  //         .then(async () => {
  //           // this.utils.presentToast('Added New Store Successfully');
  //         })
  //         .catch((err) => console.log(err));
  //     }
  //   }
}
