<div class="card">
    <div class="card-body">
        <h5 class="font-weight-bold">Popular Products</h5>
        <br />
        <div class="row listing-card-container" *ngIf="data.length > 0">
            <div class="col-6 col-sm-3 mb-4 position-relative" *ngFor="let item of data">
                <div class="icon-row-item" style="width: inherit;">
                    <app-add-item #addItem source="product" [item]="item" (addItemEvent)="onItemAdd($event)">
                    </app-add-item>
                    <div class="card-body text-center pb-2">
                        <img [alt]="item.name" [src]="item.image" class="img-thumbnail border-0 list-thumbnail" />
                    </div>
                    <div class="card-body text-left pt-0">
                        <p class="card-text font-weight-semibold mb-0">
                            {{item.name}}
                        </p>
                        <p class="text-muted mb-1">{{ item.weight }}</p>
                        <div class="">
                            <span class="float-left">$</span>
                            <h6 class="float-left font-weight-bold">{{item.saleprice}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row listing-card-container" *ngIf="data.length === 0">
            <div class="col-6 mb-4">
                <div class="card-body">No popular products</div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="text-center">
    <img [alt]="item.name" [src]="item.image" class="img-thumbnail border-0 mb-4 list-thumbnail" />
    <p class="list-item-heading mb-1">
        {{ item.name }}
    </p>
    <p class="mb-2 text-muted text-small">{{ item.weight }}</p>
    <button type="button" class="btn btn-sm btn-outline-primary">
        Add to Cart
    </button>
    <p class="mb-4">${{ item.saleprice }}</p>
    <app-add-item #addItem source="product" [item]="item" (addItemEvent)="onItemAdd($event)">
    </app-add-item>
</div> -->

