<div dropdown class="user d-inline-block mr-2" >
    <button id="button-basic" dropdownToggle type="button" id="searchButton" class="header-icon btn btn-empty">
        <i class="simple-icon-magnifier"></i>
    </button>
    <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="searchDropdown">
            <div
                class="dropdown-menu dropdown-menu-right mt-3"
                *dropdownMenu
                role="menu"
            >
                <a class="dropdown-item c-pointer">Search Store</a>
                <a class="dropdown-item c-pointer">Search Product</a>
            </div>
    </div>
</div>