import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TopnavComponent } from './topnav/topnav.component';
import { TranslateModule } from '@ngx-translate/core';
import { ArchwizardModule } from 'angular-archwizard';
import { RouterModule } from '@angular/router';
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';
import { FooterComponent } from './footer/footer.component';
import { HeadingComponent } from './heading/heading.component';
import { ApplicationMenuComponent } from './application-menu/application-menu.component';
import { FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalSearchShopsComponent } from './modal-search-shops/modal-search-shops.component';
import { ModalSearchLocationComponent } from './modal-search-location/modal-search-location.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalProcessCheckoutComponent } from './modal-process-checkout/modal-process-checkout.component';
import { ModalProcessCreateStoreComponent } from './modal-process-create-store/modal-process-create-store.component';
import { ModalProcessCreateCategoryComponent } from './modal-process-create-category/modal-process-create-category.component';
import { ModalProcessCreateProductComponent } from './modal-process-create-product/modal-process-create-product.component';
import { ModalProcessLoginComponent } from './modal-process-login/modal-process-login.component';
import { DropdownNotificationComponent } from './dropdown-notification/dropdown-notification.component';
import { DropdownSearchComponent } from './dropdown-search/dropdown-search.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { WizardOrderComponent } from './wizard-order/wizard-order.component';
import { DropdownCartComponent } from './dropdown-cart/dropdown-cart.component';
import { ComponentsStateButtonModule } from 'src/app/components/state-button/components.state-button.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ShopsContainersModule } from '../shops/shops.containers.module';
import { EditUserModalComponent } from './topnav/edit-user-modal/edit-user-modal.component';
import { EditAddressesModalComponent } from './topnav/edit-addresses-modal/edit-addresses-modal.component';
import { SearchStoreModalComponent } from './topnav/search-store-modal/search-store-modal.component';
import { SearchProductModalComponent } from './topnav/search-product-modal/search-product-modal.component';
import { CartComponent } from './cart/cart.component';

@NgModule({
  declarations: [
    TopnavComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    FooterComponent,
    HeadingComponent,
    ApplicationMenuComponent,
    ModalSearchShopsComponent,
    ModalSearchLocationComponent,
    ModalProcessCheckoutComponent,
    ModalProcessCreateStoreComponent,
    ModalProcessCreateCategoryComponent,
    ModalProcessCreateProductComponent,
    ModalProcessLoginComponent,
    DropdownNotificationComponent,
    DropdownSearchComponent,
    SideMenuComponent,
    WizardOrderComponent,
    DropdownCartComponent,
    EditUserModalComponent,
    EditAddressesModalComponent,
    SearchStoreModalComponent,
    SearchProductModalComponent,
    CartComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    TranslateModule,
    RouterModule,
    CollapseModule,
    ArchwizardModule,
    ComponentsStateButtonModule,
    ShopsContainersModule,
    SimpleNotificationsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  exports: [
    TopnavComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    FooterComponent,
    HeadingComponent,
    ApplicationMenuComponent,
    ModalSearchShopsComponent,
    ModalSearchLocationComponent,
    ModalProcessCheckoutComponent,
    ModalProcessCreateStoreComponent,
    ModalProcessCreateCategoryComponent,
    ModalProcessCreateProductComponent,
    ModalProcessLoginComponent,
    DropdownNotificationComponent,
    WizardOrderComponent,
    DropdownCartComponent,
    CartComponent,
    SideMenuComponent
  ],
  providers: [
    BsModalService
  ]
})
export class LayoutContainersModule { }
