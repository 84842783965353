<div class="row">
  <div class="col-12 mb-4 card">
    <div class="d-flex flex-row border-bottom" *ngFor="let item of categories">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body p-3 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero"
        >
          <div class="min-width-zero">
            <span class="c-pointer" (click)="showProductsFromCategory(item.id)">
              <p class="list-item-heading mb-1 truncate">{{ item.name }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
