<div class="row listing-card-container" *ngIf="productInfo?.length > 0">
  <div
    class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-6 mb-4 pr-1"
    *ngFor="let item of productInfo; let i = index"
  >
    <div class="card-body">
      <div class="text-center">
        <img
          [alt]="item.name"
          [src]="item.image"
          class="img-thumbnail border-0 mb-4 list-thumbnail"
        />
        <p class="list-item-heading mb-1">{{ item.name }} ({{ item.weight }})</p>
        <p class="mb-2 text-muted text-small">{{ item.brand }}</p>
        <!-- <button type="button" class="btn btn-sm btn-outline-primary">
                    Add to Cart
                </button> -->
        <p class="mb-4">Price: ${{ item.saleprice }}</p>
        <app-add-item
          #addItem
          source="product"
          [item]="item" 
          (addItemEvent)="onItemAdd($event)"
        ></app-add-item>
      </div>
    </div>
  </div>
</div>
<div class="row listing-card-container d-flex align-items-center justify-content-center" *ngIf="productInfo?.length === 0">
  No products available in this category
</div>
