import { Injectable } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/firestore';
import { UtilsService } from './utils.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  productCategoryRef: AngularFirestoreCollection<ProductCategory>;

  constructor(public Afs: AngularFirestore, public utils: UtilsService) {}

  public async createCategory(result) {
    this.productCategoryRef = this.Afs.collection<ProductCategory>(
      'categories'
    );
    await this.productCategoryRef.add({ ...result }).then((snapshot) => {
      console.log(snapshot);
    });
  }

  public async updateCategory(id, result) {
    this.productCategoryRef = this.Afs.collection<ProductCategory>(
      'categories'
    );
    await this.productCategoryRef.doc(id).set({ ...result });
  }

  public async deleteCategory(id) {
    this.productCategoryRef = this.Afs.collection<ProductCategory>('categories');
    await this.productCategoryRef.doc(id).delete();
  }

  getProductCategories(bizcat: string) {
    this.productCategoryRef = this.Afs.collection<ProductCategory>(
      'categories',
      (ref) => ref.where('bizcat', '==', bizcat)
    );
    return this.productCategoryRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getCategories() {
    this.productCategoryRef = this.Afs.collection<ProductCategory>(
      'categories'
    );
    return this.productCategoryRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getCategoryParams(si: any) {
    const uid = localStorage.getItem('uid');
    return {
      uid: uid,
      name: si.name,
      bizcat: si.bizcat,
      discount: si.discount,
      description: si.description,
      image: si.image,
    };
  }
}

export class ProductCategory {
  constructor() {}
  id: any;
  bizcat: string;
  description: string;
  discount = 0;
  image: string;
  name: string;
  uid: string;
}
