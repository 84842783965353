import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MapboxService, Feature } from 'src/app/data/mapbox.service';
import { UserService } from 'src/app/data/user.service';
@Component({
  selector: 'app-edit-addresses-modal',
  templateUrl: './edit-addresses-modal.component.html',
  styles: [],
})
export class EditAddressesModalComponent implements OnInit {
  modalRef: BsModalRef;
  newModalRef: BsModalRef;
  deleteModalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-right',
  };
  username = '';
  userId = '';
  addresses = [];
  defaultAddress = 0;
  selectedFinalAddress = '';
  possibleAddresses = [];
  selectedAddress = -1;

  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  @ViewChild('newAddress') newAddress: TemplateRef<any>;
  @ViewChild('confirmDelete') confirmDelete: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    public userService: UserService,
    private mapboxService: MapboxService
  ) {}

  ngOnInit() {
    this.username = localStorage.getItem('fullname');
    this.userId = localStorage.getItem('uid');
    this.getAllAddresses();
  }

  show(): void {
    this.modalRef = this.modalService.show(this.template, this.config);
  }

  getAllAddresses() {
    if (
      localStorage.getItem(this.userId + 'addresses') === null ||
      localStorage.getItem(this.userId + 'addresses') === undefined
    ) {
      this.addresses = [];
    } else {
      this.addresses = JSON.parse(
        localStorage.getItem(this.userId + 'addresses')
      ).items;
      this.defaultAddress = JSON.parse(
        localStorage.getItem(this.userId + 'addresses')
      ).selected;
    }
  }

  deleteAddress(index, event) {
    event.stopPropagation();
    this.selectedAddress = index;
    this.deleteModalRef = this.modalService.show(this.confirmDelete, {
      class: 'modal-lg',
    });
  }

  confirm(): void {
    if (this.selectedAddress === this.defaultAddress) {
      this.defaultAddress = -1;
    }
    let tempAddresses = this.addresses;
    tempAddresses.splice(this.selectedAddress, 1);
    localStorage.setItem(
      this.userId + 'addresses',
      JSON.stringify({ items: this.addresses, selected: this.defaultAddress })
    );
    this.deleteModalRef.hide();
  }

  decline(): void {
    this.deleteModalRef.hide();
  }

  makeDefaultAddress(index) {
    this.defaultAddress = index;
    localStorage.setItem(
      this.userId + 'addresses',
      JSON.stringify({ items: this.addresses, selected: index })
    );
  }

  async Continue() {
    this.modalRef.hide();
    this.newModalRef = this.modalService.show(this.newAddress, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
  }

  searchAddress(event) {
    this.mapboxService
      .searchWord(event.target.value)
      .subscribe((features: Feature[]) => {
        const results = features.map((feat) => feat.place_name);
        this.mapPossibleAddresses(features);
      });
  }

  mapPossibleAddresses(results) {
    let tempAddresses = [];
    results.forEach((item) => {
      const address: any = {};
      const zipcode = item.context.filter((val) => val.id.includes('postcode'));
      if (zipcode !== undefined && zipcode[0] !== undefined) {
        if (zipcode[0].text !== undefined) {
          address.zipcode = zipcode[0].text;
        } else {
          address.zipcode = '';
        }
      }

      const state = item.context.filter((val) => val.id.includes('region'));
      if (state !== undefined && state[0] !== undefined) {
        if (state[0].text !== undefined) {
          address.state = state[0].text;
        } else {
          address.state = '';
        }
      }

      const city = item.context.filter((val) => val.id.includes('place'));
      if (city !== undefined && city[0] !== undefined) {
        if (city[0].text !== undefined) {
          address.city = city[0].text;
        } else {
          address.city = '';
        }
      }

      const country = item.context.filter((val) => val.id.includes('country'));
      if (country !== undefined && country[0] !== undefined) {
        if (country[0].text !== undefined) {
          address.country = country[0].text;
        } else {
          address.country = '';
        }
      }

      if (item.address === undefined) {
        if (item.text !== undefined) {
          address.streetaddress = item.text;
        } else {
          address.streetaddress = '';
        }
      } else {
        if (item.text !== undefined) {
          address.streetaddress = item.address + ' ' + item.text;
        } else {
          address.streetaddress = item.address;
        }
      }
      tempAddresses.push(address);
    });
    this.possibleAddresses = tempAddresses;
  }

  addressSelect(address) {
    this.newModalRef.hide();
    this.show();
    if (address.streetaddress !== undefined) {
      this.selectedFinalAddress = address.streetaddress;
    }
    if (address.city !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ', ' + address.city;
    }
    if (address.state !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ', ' + address.state;
    }
    if (address.country !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ', ' + address.country;
    }
    if (address.zipcode !== undefined) {
      this.selectedFinalAddress =
        this.selectedFinalAddress + ' - ' + address.zipcode;
    }
    this.addresses.push({ fullAddress: this.selectedFinalAddress });
    localStorage.setItem(
      this.userId + 'addresses',
      JSON.stringify({ items: this.addresses, selected: this.defaultAddress })
    );
    this.selectedFinalAddress = '';
    this.possibleAddresses = [];
  }

  closeNewModal() {
    this.newModalRef.hide();
    this.show();
  }
}
