import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { AddItemComponent } from '../add-item/add-item.component';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
})
export class ProductsListComponent {
  @Input() productInfo: any;

  @Output()
  addItemEvent: EventEmitter<any> = new EventEmitter();

  @ViewChildren(AddItemComponent) addItem: QueryList<AddItemComponent>;

  constructor() {}

  onItemAdd(event) {
    this.addItemEvent.emit(event);
  }

  onItemUpdate(event) {
    this.addItem.forEach((child) => { child.handleUpdateItem(event) })
  }
}
