<button type="button" (click)="openModal(template)" class="language-button dropdown-toggle btn btn-light btn-sm">
    <i class="iconsminds-map-marker-2 d-inline-block"></i>
    Naperville
    <span class="caret"></span>
</button>

<ng-template #template>
  <div class="modal-header navbar">
    <input placeholder="Search Shops" [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
    <!-- <div class="search" (click)="searchAreaClick($event)">
        <input placeholder="Search Shops" [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
        <span class="search-icon" (click)="searchClick($event)">
            <i class="simple-icon-magnifier"></i>
        </span>
    </div> -->
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngFor="let item of items">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque delectus enim esse excepturi, impedit,
      iste magnam officia optio, quam quis quisquam saepe sint unde velit vitae! Animi in iusto ut?</p>
  </div>
</ng-template>
