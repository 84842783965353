import { Injectable } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/firestore';
import { UtilsService } from './utils.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  myProductRef: AngularFirestoreCollection<Product>;

  constructor(public Afs: AngularFirestore, public utils: UtilsService) {}

  public async createProduct(result) {
    this.myProductRef = this.Afs.collection<Product>('products');
    await this.myProductRef.add({ ...result }).then((snapshot) => {
      console.log(snapshot);
    });
  }

  public async updateProduct(id, result) {
    this.myProductRef = this.Afs.collection<Product>('products');
    await this.myProductRef
      .doc(id)
      .set({ ...result });
  }

  public async deleteProduct(id) {
    this.myProductRef = this.Afs.collection<Product>('products');
    await this.myProductRef
      .doc(id)
      .delete();
  }

  getProductsByCategory(categoryId: string) {
    this.myProductRef = this.Afs.collection<Product>('products', (ref) =>
      ref.where('prodcat', '==', categoryId)
    );
    return this.myProductRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getProducts() {
    this.myProductRef = this.Afs.collection<Product>('products');
    return this.myProductRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getPopularProducts() {
    // this.myProductRef = this.Afs.collection<Product>('products', (ref) =>
    //   ref.where('ispopular', '==', 'true')
    // );
    this.myProductRef = this.Afs.collection<Product>('products');
    return this.myProductRef.snapshotChanges().pipe(
      map((res) =>
        res.map((dataItems) => {
          const data = dataItems.payload.doc.data(),
            id = dataItems.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getProductParams(si: any) {
    const uid = localStorage.getItem('uid');
    let instock = false;
    let popular = false;
    if (si.isinstock !== undefined && si.isinstock !== null) {
      instock = si.isinstock;
    }
    if (si.ispopular !== undefined && si.ispopular !== null) {
      popular = si.ispopular;
    }
    return {
      uid: uid,
      name: si.name,
      regularprice: si.regularprice,
      saleprice: si.saleprice,
      brand: si.brand,
      weight: si.weight,
      isinstock: instock,
      ispopular: popular,
      description: si.description,
      image: si.image,
      bizcat: si.bizcat,
      prodcat: si.prodcat,
    };
  }
}

export class Product {
  constructor() {}
  id: any;
  name: string;
  regularprice = 0;
  saleprice = 0;
  brand: string;
  weight: string;
  isinstock = false;
  ispopular = false;
  description: string;
  image: string;
  bizcat: string;
  prodcat: string;
}
