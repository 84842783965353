import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html'
})

export class ProductCategoriesComponent {

    @Input() categoryInfo: any;
    @Input() shopId: string;

    constructor(public router: Router) { }

    goToCategoryProducts(categoryId: string): void {
        localStorage.setItem('prodcat', categoryId);
        this.router.navigate(['/' + this.shopId + '/' + categoryId]);
    }

}
